<template>
  <div>
    <div class="newTitlleBlock">
      <p v-html="$t('accordionBlock.titleBlock')"></p>
    </div>
    <section class="accordionBlock">
      <section class="faq" id="faq">
        <div class="container faq__container">
          <h2 class="faq__title" v-html="$t('accordionBlock.faq.title')"></h2>
          <div class="faq__items">
            <div class="item" v-for="(item, index) in faqItems" :key="index">
              <div class="item__header" @click="toggleAccordion(index)">
                <div class="item__question">{{ $t(item.questionKey) }}</div>
                <div class="item__arrow" :class="{ 'expanded': item.expanded }">
                  <i class="fas fa-chevron-down"></i>
                </div>
              </div>
              <div class="item__answer" v-if="item.expanded">{{ $t(item.answerKey) }}</div>
            </div>
          </div>
        </div>
      </section>

      <div class="telegramLink">
        <p v-html="$t('accordionBlock.telegram.text')"></p>
        <a href="https://t.me/roskinbud">
          <img src="@/assets/icons/TelegramLink.svg" alt="">
        </a>
      </div>
    </section>
  </div>
</template>

<script>
import { ref } from 'vue';
import '@fortawesome/fontawesome-free/css/all.css';

export default {
  name: 'AccordionBlock',
  setup() {
    const faqItems = ref([
      {
        questionKey: 'accordionBlock.faq.faqItems.item0.question',
        answerKey: 'accordionBlock.faq.faqItems.item0.answer',
        expanded: true,
      },
      {
        questionKey: 'accordionBlock.faq.faqItems.item1.question',
        answerKey: 'accordionBlock.faq.faqItems.item1.answer',
        expanded: false,
      },
      {
        questionKey: 'accordionBlock.faq.faqItems.item2.question',
        answerKey: 'accordionBlock.faq.faqItems.item2.answer',
        expanded: false,
      },
      {
        questionKey: 'accordionBlock.faq.faqItems.item3.question',
        answerKey: 'accordionBlock.faq.faqItems.item3.answer',
        expanded: false,
      },
      {
        questionKey: 'accordionBlock.faq.faqItems.item4.question',
        answerKey: 'accordionBlock.faq.faqItems.item4.answer',
        expanded: false,
      },
      {
        questionKey: 'accordionBlock.faq.faqItems.item5.question',
        answerKey: 'accordionBlock.faq.faqItems.item5.answer',
        expanded: false,
      },
      {
        questionKey: 'accordionBlock.faq.faqItems.item6.question',
        answerKey: 'accordionBlock.faq.faqItems.item6.answer',
        expanded: false,
      },
      {
        questionKey: 'accordionBlock.faq.faqItems.item7.question',
        answerKey: 'accordionBlock.faq.faqItems.item7.answer',
        expanded: false,
      },
      {
        questionKey: 'accordionBlock.faq.faqItems.item8.question',
        answerKey: 'accordionBlock.faq.faqItems.item8.answer',
        expanded: false,
      }
    ]);

    const toggleAccordion = (index) => {
      faqItems.value[index].expanded = !faqItems.value[index].expanded;
    };

    return {
      faqItems,
      toggleAccordion,
    };
  }
};
</script>

<style scoped>

.accordionBlock {
  background: #322929;
  padding: 20px;
}
.newTitlleBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ED7C0E;
  padding: 40px 0;
}

.newTitlleBlock p {
  text-align: center;
  padding: 5px;
  color: white;
  font-size: 18px;
  line-height: 28px;
  text-transform: uppercase;
}

.newTitlleBlock a {
  color: #322929;
}

a {
  text-decoration: none;
}

.faq {
  padding: 20px;
}

.faq__container {
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  background: #322929;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.faq__title {
  font-size: 24px;
  margin-bottom: 20px;
  color: white;
}

.faq__title span {
  color: #ED7C0E;
}

.faq__items {
  border-top: 1px solid #ddd;
  margin-top: 20px;
}

.item {
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
  cursor: pointer;
  margin-bottom: 10px;
}

.item__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.item__question {
  font-size: 18px;
  color: white;
}

.item__arrow {
  font-size: 18px;
  color: white;
}

.item__arrow.expanded {
  transform: rotate(180deg);
}

.item__answer {
  padding: 5px;
  font-size: 16px;
  color: #ED7C0E;
  margin-top: 20px;
}

.telegramLink {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.telegramLink p {
  width: 500px;
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.telegramLink p span {
  color: #ED7C0E;
}

.telegramLink img {
  width: 100px;
  height: 100px;
  background: #322929;
}

.accordionBlock {
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex-wrap: wrap;
  margin: 50px 100px;
  padding: 15px;
  background: #322929;
  border-radius: 2px;
}

@media (max-width: 430px) {
  .telegramLink p {
    width: 300px;
  }
  .newTitlleBlock p {
    width: 300px;
    font-size: 15px;
  }
  .accordionBlock {
    margin: 30px 0;
    padding-bottom: 15px;
    align-items: center;
    justify-content: center;
  }
  .faq__container {
    max-width: 350px;
  }
}

@media (min-width: 431px) and (max-width: 1024px) {
  .telegramLink p {
    width: 500px;
  }
  .accordionBlock {
    margin: 30px 20px;
    padding-bottom: 15px;
    align-items: center;
    justify-content: center;
  }
  .faq__container {
    max-width: 500px;
  }
}
</style>
