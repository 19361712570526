<template>
    <menu class="menu">
    <router-link :to="`/${currentLocale}`">{{ $t('menu.menuTitleMain') }}</router-link>
    <router-link :to="`/${currentLocale}/about`">{{ $t('menu.menuTitleAboutus') }}</router-link>
    <router-link :to="`/${currentLocale}/portfolio`">{{ $t('menu.menuTitlePortfolio') }}</router-link>
    <router-link :to="`/${currentLocale}/reviews`">{{ $t('menu.menuTitleReviews') }}</router-link>
    <router-link :to="`/${currentLocale}/calculator`">{{ $t('menu.menuTitleCalculator') }}</router-link>
    <router-link :to="`/${currentLocale}/now`">{{ $t('menu.menuTitleNow') }}</router-link>
    <router-link :to="`/${currentLocale}/fasting`">{{ $t('menu.menuTitleFasting') }}</router-link>
    <router-link :to="`/${currentLocale}/contacts`">{{ $t('menu.menuTitleContacts') }}</router-link>
  </menu>
</template>

<script>
export default {
  name: 'MainMenu',
  computed: {
    currentLocale() {
      // Получаем текущую локаль из i18n
      return this.$i18n.locale;
    }
  }
  
}
</script>

<style scoped>
  menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: baseline;
    width: 500px;
  }

  menu a {
    color: #ffffff;

    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
  }

  menu a:hover {
    color: #ED7C0E;
  }
</style>