<template>
  <div>
    <HeaderSite></HeaderSite>
  </div>

  <section class="portfolioComponentSection">
    <div class="options">
      <!-- Используем $t для вывода перевода -->
      <h1 class="name_portfolioItem">{{ $t(portfolioOption.name_portfolioItemKey) }}</h1>
      <div class="optionList">
        <div class="location optionItem">
          <img src="@/assets/icons/portfolioIcons/location.svg" alt="" class="optionIcon">
          <p class="location_p optionP">{{ $t(portfolioOption.location_pKey) }}</p>
        </div>
        <div class="footage optionItem">
          <img src="@/assets/icons/portfolioIcons/footage.svg" alt="" class="optionIcon">
          <p class="footage_p optionP">{{ $t(portfolioOption.footage_pKey) }}</p>
        </div>
        <div class="cost optionItem">
          <img src="@/assets/icons/portfolioIcons/cost.svg" alt="" class="optionIcon">
          <p class="cost_p optionP">{{ $t(portfolioOption.cost_pKey) }}</p>
        </div>
        <div class="period optionItem">
          <img src="@/assets/icons/portfolioIcons/period.svg" alt="" class="optionIcon">
          <p class="period_p optionP">{{ $t(portfolioOption.period_pKey) }}</p>
        </div>
      </div>
      <h1 class="h1_completedWorks">Виконані роботи:</h1>
      <p class="completedWorks_p">{{ $t(portfolioOption.completedWorks_pKey) }}</p>
    </div>

    <div class="portfolioImages">
      <div v-for="(img, index) in portfolioImages" :key="index" @click="openFullScreen(index)">
        <img :src="getImgUrl(img.srcImg)" alt="">
      </div>
    </div>

    <div v-if="isFullScreen" class="fullScreenOverlay" @click="closeFullScreen">
      <div class="fullScreenImage">
        <img :src="getImgUrl(fullScreenImage.srcImg)" alt="">
        <button class="closeButton" @click="closeFullScreen">Close</button>
      </div>
    </div>

    <div class="dividedLine">
      <h1>Інші приклади робіт</h1>
    </div>

    <div class="anotherItems">
      <prototipePortfolio :slides="otherItems"></prototipePortfolio>
    </div>
  </section>

  <FooterSite></FooterSite>
</template>

<script>
import prototipePortfolio from '@/components/completedWorks/prototipeBlockPortfolio.vue'

export default {
  name: 'DubechnyaComponent',
  components: {
    prototipePortfolio
  },
  data() {
    return {
      isFullScreen: false,
      fullScreenImage: null,
      portfolioImages: [
        { "id": 1, "srcImg": "sliders/dubeshnya/01.webp" },
        { "id": 2, "srcImg": "sliders/dubeshnya/02.webp" },
        { "id": 3, "srcImg": "sliders/dubeshnya/03.webp" },
        { "id": 4, "srcImg": "sliders/dubeshnya/04.webp" },
        { "id": 5, "srcImg": "sliders/dubeshnya/05.webp" },
        { "id": 6, "srcImg": "sliders/dubeshnya/06.webp" },
        { "id": 7, "srcImg": "sliders/dubeshnya/07.webp" },
        { "id": 8, "srcImg": "sliders/dubeshnya/08.webp" },
        { "id": 9, "srcImg": "sliders/dubeshnya/09.webp" }
      ],
      portfolioOption: {
        name_portfolioItemKey: "completedWorks.item1.name_portfolioItem",
        location_pKey: "completedWorks.item1.location_p",
        footage_pKey: "completedWorks.item1.footage_p",
        cost_pKey: "completedWorks.item1.cost_p",
        period_pKey: "completedWorks.item1.period_p",
        completedWorks_pKey: "completedWorks.item1.completedWorks_p"
      }
      ,
      otherItems: [
      {
        id: "item1",
        photos: [
          { id: 1, srcImg: "sliders/zapovitniy/01.webp" },
          { id: 2, srcImg: "sliders/zapovitniy/02.webp" },
          { id: 3, srcImg: "sliders/zapovitniy/03.webp" },
          { id: 4, srcImg: "sliders/zapovitniy/04.webp" }
        ],
        name_portfolioItemKey: "completedWorks.item2.name_portfolioItem",
        location_pKey: "completedWorks.item2.location_p",
        footage_pKey: "completedWorks.item2.footage_p",
        cost_pKey: "completedWorks.item2.cost_p",
        period_pKey: "completedWorks.item2.period_p",
        completedWorks_pKey: "completedWorks.item2.completedWorks_p",
        portfolioLink: "/zapovitniy"
      },
      {
        id: "item2",
        photos: [
          { id: 1, srcImg: "sliders/jkYaskraviy/01.webp" },
          { id: 2, srcImg: "sliders/jkYaskraviy/02.webp" },
          { id: 3, srcImg: "sliders/jkYaskraviy/03.webp" },
          { id: 4, srcImg: "sliders/jkYaskraviy/04.webp" },
          { id: 5, srcImg: "sliders/jkYaskraviy/05.webp" },
          { id: 6, srcImg: "sliders/jkYaskraviy/06.webp" },
          { id: 7, srcImg: "sliders/jkYaskraviy/07.webp" },
          { id: 8, srcImg: "sliders/jkYaskraviy/08.webp" },
          { id: 9, srcImg: "sliders/jkYaskraviy/09.webp" }
        ],
        name_portfolioItemKey: "completedWorks.item3.name_portfolioItem",
        location_pKey: "completedWorks.item3.location_p",
        footage_pKey: "completedWorks.item3.footage_p",
        cost_pKey: "completedWorks.item3.cost_p",
        period_pKey: "completedWorks.item3.period_p",
        completedWorks_pKey: "completedWorks.item3.completedWorks_p",
        portfolioLink: "/yaskraviy"
      },
      {
        id: "item3",
        photos: [
          { id: 1, srcImg: "sliders/sinergiyaCity/01.webp" },
          { id: 2, srcImg: "sliders/sinergiyaCity/02.webp" },
          { id: 3, srcImg: "sliders/sinergiyaCity/03.webp" },
          { id: 4, srcImg: "sliders/sinergiyaCity/04.webp" },
          { id: 5, srcImg: "sliders/sinergiyaCity/05.webp" },
          { id: 6, srcImg: "sliders/sinergiyaCity/06.webp" },
          { id: 7, srcImg: "sliders/sinergiyaCity/07.webp" },
          { id: 8, srcImg: "sliders/sinergiyaCity/08.webp" },
          { id: 9, srcImg: "sliders/sinergiyaCity/09.webp" },
          { id: 10, srcImg: "sliders/sinergiyaCity/10.webp" },
          { id: 11, srcImg: "sliders/sinergiyaCity/11.webp" }
        ],
        name_portfolioItemKey: "completedWorks.item4.name_portfolioItem",
        location_pKey: "completedWorks.item4.location_p",
        footage_pKey: "completedWorks.item4.footage_p",
        cost_pKey: "completedWorks.item4.cost_p",
        period_pKey: "completedWorks.item4.period_p",
        completedWorks_pKey: "completedWorks.item4.completedWorks_p",
        portfolioLink: "/sinergiyacity"
      },
      {
        id: "item4",
        photos: [
          { id: 1, srcImg: "sliders/varshavskyi/01.webp" },
          { id: 2, srcImg: "sliders/varshavskyi/02.webp" },
          { id: 3, srcImg: "sliders/varshavskyi/03.webp" },
          { id: 4, srcImg: "sliders/varshavskyi/04.webp" },
          { id: 5, srcImg: "sliders/varshavskyi/05.webp" },
          { id: 6, srcImg: "sliders/varshavskyi/06.webp" }
        ],
        name_portfolioItemKey: "completedWorks.item5.name_portfolioItem",
        location_pKey: "completedWorks.item5.location_p",
        footage_pKey: "completedWorks.item5.footage_p",
        cost_pKey: "completedWorks.item5.cost_p",
        period_pKey: "completedWorks.item5.period_p",
        completedWorks_pKey: "completedWorks.item5.completedWorks_p",
        portfolioLink: "/varshavskiy"
      },
      {
        id: "item5",
        photos: [
          { id: 1, srcImg: "sliders/fainaTown/01.webp" },
          { id: 2, srcImg: "sliders/fainaTown/02.webp" },
          { id: 3, srcImg: "sliders/fainaTown/03.webp" },
          { id: 4, srcImg: "sliders/fainaTown/04.webp" },
          { id: 5, srcImg: "sliders/fainaTown/05.webp" },
          { id: 6, srcImg: "sliders/fainaTown/06.webp" },
          { id: 7, srcImg: "sliders/fainaTown/07.webp" },
          { id: 8, srcImg: "sliders/fainaTown/08.webp" }
        ],
        name_portfolioItemKey: "completedWorks.item6.name_portfolioItem",
        location_pKey: "completedWorks.item6.location_p",
        footage_pKey: "completedWorks.item6.footage_p",
        cost_pKey: "completedWorks.item6.cost_p",
        period_pKey: "completedWorks.item6.period_p",
        completedWorks_pKey: "completedWorks.item6.completedWorks_p",
        portfolioLink: "/faynatown"
      }
    ]

    };
  },
  methods: {
    getImgUrl(pic) {
      return require('@/assets/' + pic);
    },
    openFullScreen(index) {
      this.isFullScreen = true;
      this.fullScreenImage = this.portfolioImages[index];
      document.body.style.overflow = 'hidden';
    },
    closeFullScreen() {
      this.isFullScreen = false;
      this.fullScreenImage = null;
      document.body.style.overflow = '';
    }
  }
};
</script>

<style scoped>
/* Стили остаются без изменений */
.portfolioComponentSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 130px;
  width: 100%;
}
.portfolioImages {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
  padding: 100px;
}
.portfolioImages img {
  width: 280px;
  height: 450px;
}
.options {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding-top: 30px;
  width: 750px;
}
.name_portfolioItem {
  color: var(--SecondColor, #322929);
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  width: 500px;
  text-align: center;
}
.optionIcon {
  width: 45px;
  height: 45px;
  flex-shrink: 0;
}
.optionItem {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.optionList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 35px;
}
.h1_completedWorks {
  color: #322929;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}
.completedWorks_p {
  width: 525px;
  flex-shrink: 0;
  text-align: center;
}
.optionP {
  color: #322929;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.anotherItems {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.dividedLine {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  background: rgba(50, 41, 41, 0.8);
  height: 100px;
  color: white;
}
.fullScreenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fullScreenImage img {
  max-width: 80%;
  max-height: 90vh;
}
.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  z-index: 1000;
}
@media (max-width: 430px) {
  .portfolioImages {
    padding: 10px;
    align-items: center;
    justify-content: center;
  }
  .options {
    width: 350px;
  }
  .name_portfolioItem {
    font-size: 20px;
    width: 300px;
    text-align: center;
  }
  .h1_completedWorks {
    font-size: 20px;
    text-align: center;
  }
  .completedWorks_p {
    width: 325px;
    text-align: center;
  }
  .optionP {
    font-size: 16px;
    text-align: center;
  }
}
</style>
