<template>
  <section class="otherServices">
    <h1 class="otherServices_title">{{ $t('otherServices.title') }}</h1>
    <div class="otherServices_list">
      <div class="otherServices_item" v-for="(item, index) in serviceItems" :key="index">
        <img class="imgItem" :src="item.image" alt="">
        <div class="middle">
          <h2>{{ $t(item.titleKey) }}</h2>
          <ul>
            <li class="tag" v-for="(tagKey, idx) in item.tagKeys" :key="idx">
              {{ $t(tagKey) }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OtherServices',
  data() {
    return {
      serviceItems: [
        {
          image: require('@/assets/sliders/designs/09.webp'),
          titleKey: "otherServices.items.item1.title",
          tagKeys: [
            "otherServices.items.item1.tag1",
            "otherServices.items.item1.tag2",
            "otherServices.items.item1.tag3",
            "otherServices.items.item1.tag4"
          ]
        },
        {
          image: require('@/assets/sliders/dubeshnya/06.webp'),
          titleKey: "otherServices.items.item2.title",
          tagKeys: [
            "otherServices.items.item2.tag1",
            "otherServices.items.item2.tag2",
            "otherServices.items.item2.tag3",
            "otherServices.items.item2.tag4",
            "otherServices.items.item2.tag5"
          ]
        },
        {
          image: require('@/assets/sliders/zapovitniy/01.webp'),
          titleKey: "otherServices.items.item3.title",
          tagKeys: [
            "otherServices.items.item3.tag1",
            "otherServices.items.item3.tag2",
            "otherServices.items.item3.tag3",
            "otherServices.items.item3.tag4",
            "otherServices.items.item3.tag5",
            "otherServices.items.item3.tag6",
            "otherServices.items.item3.tag7"
          ]
        }
      ]
    };
  }
};
</script>

<style scoped>
.otherServices {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  margin: 50px 5vw;
}

.otherServices_title {
  text-align: center;
  color: #322929;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 50px;
}

.otherServices_list {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  justify-content: center;
}

.otherServices_item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 50px;
}

.imgItem {
  height: 130px;
  width: 80px;
  border-radius: 40px;
  transform: rotate(15deg);
}

.middle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  width: 500px;
}

.middle h2 {
  color: #322929;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding-top: 10px;
}

.middle ul {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: flex-start;
}

.middle ul .tag {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: #322929;
  color: white;
  padding: 8px;
  border-radius: 2px;
}

.middle p {
  max-width: 300px;
}

@media (max-width: 430px) {
  .otherServices {
    margin: 50px 10px;
  }
  .otherServices_title {
    font-size: 26px;
  }
  .middle {
    align-items: center;
    justify-content: center;
  }
  .middle ul {
    align-items: center;
    justify-content: center;
    width: 350px;
  }
  .otherServices_item {
    max-width: 390px;
  }
}
</style>
