<template>
  <section class="formBlock">
    <h1><span>{{ $t('formBlock.heading') }}</span></h1>
    <MainForm2v2></MainForm2v2>
  </section>
</template>

<script>



export default {
    name: 'FormBlock',

}
</script>

<style scoped>

  .formBlock {
    background: url('@/assets/background.webp'), lightgray 50% / cover no-repeat;
    padding: 100px 100px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
  }

 

  .formBlock h1 {
    width: 600px;
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }

  .formBlock h1 span {
    color: #ED7C0E;
    padding: 5px;
    background: white;
  }



  @media (max-width: 430px) {
    .formBlock {
    padding: 20px 20px;
  }

  .formBlock h1 {
    width: 350px;
    font-size: 16px;
  }
  }
</style>