<template>
  <div class="portfolio-slider">
    <div class="slide" v-for="slide in slides" :key="slide.id">
      <div class="left-side">
        <imageSectionMini :slides="slide.photos"></imageSectionMini>
      </div>

      <div class="right-side">
        <h1 class="name_portfolioItem">{{ $t(slide.name_portfolioItemKey) }}</h1>
        <p class="completedWorks_p">{{ $t(slide.location_pKey) }}</p>
        <router-link :to="`/${currentLocale}${slide.portfolioLink}`">
          <button class="portfolioItemLink">Дивитись повністю</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import imageSectionMini from '@/components/completedWorks/imageSectionMini.vue';

export default defineComponent({
  name: 'prototipePortfolio',
  components: {
    imageSectionMini
  },
  props: {
    slides: Array
  },
  computed: {
  currentLocale() {
    return this.$i18n.locale;
  }
}
});
</script>
  
  <style scoped>


/* .slide {
  border: 1px solid #322929;
  padding: 10px;
  border-radius: 8px;
} */
  .carousel__item {
    overflow: hidden;
  }
    /* .slide {
          padding: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;    
          justify-content: top; 
      } */
    .portfolio-slider {
      display: flex;
      flex-wrap: wrap;
      gap: 25px;
      row-gap: 100px;
      align-items: baseline;
      justify-content: center;
      padding: 20px 50px;
      margin-top: 50px;
    }

  
    .right-side {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding-top: 20px;
      align-items: center;
      justify-content: center;
    }


    .portfolioItemLink {
      width: 300px;
      height: 60px;
      color: #FFF;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 60px;
      border-radius: 2px;
      background: #ED7C0E;
      border: none;
      cursor: pointer;
    }
  
  
    .right-side p, .right-side h1 {
      text-align: center;
      width: 390px;
    }
  
  
    .name_portfolioItem{
      color: var(--SecondColor, #322929);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      width: 500px;
    }
  
    .optionIcon {
      width: 45px;
      height: 45px;
      flex-shrink: 0;
    }
  
    .optionItem {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      align-items: center;
      justify-content: flex-start;
    }
  
    .optionList {
      display: grid;
      grid-template-columns: 300px 300px;
      row-gap: 40px;
      column-gap: 10px;
    }
  
    .h1_completedWorks {
      color:  #322929;
      /* Підзаголовок */
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }
  
    .completedWorks_p {
      width: 525px;
      flex-shrink: 0;
    }
  
    .optionP {
      color: #322929;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  
  
    @media (max-width: 430px) {
      .slider {
      padding: 20px 20px;
  
    }
  
    .portfolio-slider {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 5px;
      gap: 30px;
      justify-content: center;
      align-items: center;
    }
  
    .right-side {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      padding-top: 30px;
      width: 350px;
    }
  
    .right-side p, .right-side h1 {
      text-align: center;
      width: 350px;
    }


  
  
    .name_portfolioItem{
      color: var(--SecondColor, #322929);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      width: 300px;
      text-align: center;
    }
  
    .optionIcon {
      width: 45px;
      height: 45px;
      flex-shrink: 0;
    }
  
    .optionItem {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      align-items: center;
      justify-content: flex-start;
    }
  
    .optionList {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      align-items: center;
      justify-content: center;
    }
  
    .h1_completedWorks {
      color:  #322929;
      /* Підзаголовок */
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      text-align: center;
    }
  
    .completedWorks_p {
      width: 325px;
      flex-shrink: 0;
      text-align: center;
    }
  
    .optionP {
      color: #322929;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
    }
    }
  
    @media (min-width: 431px) and (max-width: 1024px) {
      .slider {
      padding: 20px 20px;
  
    }
  
    .portfolio-slider {
      display: flex;
      flex-direction: column;
      gap: 30px;
      justify-content: center;
      align-items: center;
    }
  
    .right-side {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      padding-top: 30px;
      width: 350px;
    }
  
    .right-side p, .right-side h1 {
      text-align: center;
    }
  
  
    .name_portfolioItem{
      color: var(--SecondColor, #322929);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      width: 300px;
      text-align: center;
    }
  
    .optionIcon {
      width: 45px;
      height: 45px;
      flex-shrink: 0;
    }
  
    .optionItem {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      align-items: center;
      justify-content: flex-start;
    }
  
    .optionList {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      align-items: center;
      justify-content: center;
    }
  
    .h1_completedWorks {
      color:  #322929;
      /* Підзаголовок */
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      text-align: center;
    }
  
    .completedWorks_p {
      width: 325px;
      flex-shrink: 0;
      text-align: center;
    }
  
    .optionP {
      color: #322929;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
    }
  }
  
  
  
  
  </style>