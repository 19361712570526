<template>
  <section class="menuHamburger">
    <SlideMenu :isOpen="isOpen" @closeMenu="isOpen = false" class="burgerMenu" width="390" >
      <img class="headerLogo" src="@/assets/logo.png" alt="">
     
      <router-link :to="`/${currentLocale}`" v-if="!isOpen" @click="isOpen=true">
          <a href="">{{ $t('menu.menuTitleMain') }}</a>
        </router-link>

        <router-link :to="`/${currentLocale}/about`" v-if="!isOpen" @click="isOpen=true">
          <a href="">{{ $t('menu.menuTitleAboutus') }}</a>
        </router-link>

        <router-link :to="`/${currentLocale}/portfolio`" v-if="!isOpen" @click="isOpen=true">
          <a href="">{{ $t('menu.menuTitlePortfolio') }}</a>
        </router-link>

        <router-link :to="`/${currentLocale}/reviews`" v-if="!isOpen" @click="isOpen=true">
          <a href="">{{ $t('menu.menuTitleReviews') }}</a>
        </router-link>

        <router-link :to="`/${currentLocale}/calculator`" v-if="!isOpen" @click="isOpen=true">
          <a href="">{{ $t('menu.menuTitleCalculator') }}</a>
        </router-link>

        <router-link :to="`/${currentLocale}/now`" v-if="!isOpen" @click="isOpen=true">
          <a href="">{{ $t('menu.menuTitleNow') }}</a>
        </router-link>

        <router-link :to="`/${currentLocale}/fasting`" v-if="!isOpen" @click="isOpen=true">
          <a href="">{{ $t('menu.menuTitleFasting') }}</a>
        </router-link>

        <router-link :to="`/${currentLocale}/contacts`" v-if="!isOpen" @click="isOpen=true">
          <a href="">{{ $t('menu.menuTitleContacts') }}</a>
        </router-link>

        <div class="emaiBox">
          <img src="@/assets/icons/mail.svg" alt="">
          <p>info@gmail.com</p>
        </div>

        <div class="phoneBox">
          <img src="@/assets/icons/phone.svg" alt="">
          <a href="tel:+380665246345">067 548 12 88</a>
          <a href="tel:+380665246345">073 548 12 88</a>
          <a href="tel:+380665246345">066 524 63 45</a>
        </div>
        <div class="langSwitch">
        <p class="langTitle">{{ $t('languageTitle') }}</p>
        <select v-model="currentLocale" @change="changeLanguage">
          <option value="ua">Українська</option>
          <option value="ru">Русский</option>
        </select>
        </div>
        <div class="socialLinks">
          <!-- <a href="https://t.me/+380675481288" target="_blank" rel="noopener">
            <img class="sn" src="@/assets/icons/whiteIcons/telegram-icon.svg" alt="">
          </a>
          <a href="https://www.facebook.com/olegroskinbud" target="_blank" rel="noopener">
            <img class="sn" src="@/assets/icons/whiteIcons/facebook-icon.svg" alt="">
          </a>
          <a href="https://www.instagram.com/olegroskin/" target="_blank" rel="noopener">
            <img class="sn" src="@/assets/icons/whiteIcons/instagram-icon.svg" alt="">
          </a> -->

          <a href="https://wa.me/380675481288" target="_blank">
            <img class="m" src="@/assets/icons/contactIcons/waContact.png" alt="WhatsApp">
          </a>

          <a href="https://t.me/+380675481288" target="_blank">
            <img class="m" src="@/assets/icons/contactIcons/tgContact.png" alt="Telegram">
          </a>

          <a href="viber://chat?number=%2B380675481288" target="_blank">
            <img class="m" src="@/assets/icons/contactIcons/vContact.png" alt="Viber">
          </a>
        </div>
    </SlideMenu>
  </section>
</template>

<script>
import  SlideMenu from '@/components/menu/SlideMenu.vue'   // импортируйте переходы CSS, которые вы хотите использовать, в данном случае мы используем `Slide`

export default {
  name: 'PhoneMenu',
  components: {
    SlideMenu
  },
  
  data() {
    return {
      isOpen: false,

    }
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale;
    }
  },
  methods: {
  changeLanguage(e) {
    const newLocale = e.target.value;
    this.$i18n.locale = newLocale;
    // Разбиваем текущий путь на сегменты
    const segments = this.$route.path.split('/');
    // Если первый сегмент пустой (так как путь начинается со слэша), то второй элемент – это язык
    if (segments.length > 1) {
      segments[1] = newLocale;
    } else {
      segments.push(newLocale);
    }
    const newPath = segments.join('/');
    this.$router.push(newPath);
  }
}
  
}
</script>


<style scoped>

.langTitle {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}

.langSwitch {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.langSwitch select {
  width: 50%;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 5px;
  color: #ffffff;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  /* Добавим плавное изменение при наведении */
  transition: border-color 0.3s, background 0.3s;
}

.langSwitch select:hover {
  background: rgba(255, 255, 255, 0.1);
}

.langSwitch select:focus {
  border-color: #ED7C0E;
}


  .headerLogo {
    width: 220px;
  }

  a {
    text-decoration: none;
    color: white;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
  }

  .socialLinks {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  .socialLinks img {
    width: 40px;
  }

  .emaiBox, .phoneBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
  }

  .emaiBox p, .phoneBox a {
    color: white;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .emaiBox img, .phoneBox img {
    width: 32px;
    height: 32px;
  }


</style>