<template>
  <section class="stagesBlock">
    <p class="titleStages">{{ $t('stagesBlock.titleStages') }}</p>
    <div class="stagesList">
      <div class="stagesList_item" v-for="stage in stages" :key="stage.id">
        <p class="number">{{ $t(stage.numberKey) }}</p>
        <h2 class="title">{{ $t(stage.titleKey) }}</h2>
        <p class="text">{{ $t(stage.textKey) }}</p>
      </div>
      <img src="@/assets/final-result.webp" alt="">
    </div>

    <div class="formScreen">
      <div class="leftSide">
        <h1 v-html="$t('stagesBlock.formScreen.left.h1')"></h1>
        <ul>
          <li v-for="(bulletKey, index) in bulletKeys" :key="index">{{ $t(bulletKey) }}</li>
        </ul>
      </div>
      <div class="formMainScreen">
        <p>{{ $t('stagesBlock.formScreen.formMainScreen.text') }}</p>
        <!-- <MainForm class="form"></MainForm> -->
        <MainForm2 class="mf2"></MainForm2>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'StagesBlock',
  data() {
    return {
      stages: [
        {
          id: "item0",
          numberKey: "stagesBlock.stages.item0.number",
          titleKey: "stagesBlock.stages.item0.title",
          textKey: "stagesBlock.stages.item0.text"
        },
        {
          id: "item1",
          numberKey: "stagesBlock.stages.item1.number",
          titleKey: "stagesBlock.stages.item1.title",
          textKey: "stagesBlock.stages.item1.text"
        },
        {
          id: "item2",
          numberKey: "stagesBlock.stages.item2.number",
          titleKey: "stagesBlock.stages.item2.title",
          textKey: "stagesBlock.stages.item2.text"
        },
        {
          id: "item3",
          numberKey: "stagesBlock.stages.item3.number",
          titleKey: "stagesBlock.stages.item3.title",
          textKey: "stagesBlock.stages.item3.text"
        },
        {
          id: "item4",
          numberKey: "stagesBlock.stages.item4.number",
          titleKey: "stagesBlock.stages.item4.title",
          textKey: "stagesBlock.stages.item4.text"
        }
      ],
      bulletKeys: [
        "stagesBlock.formScreen.left.bullet1",
        "stagesBlock.formScreen.left.bullet2",
        "stagesBlock.formScreen.left.bullet3",
        "stagesBlock.formScreen.left.bullet4"
      ]
    };
  }
};
</script>

<style scoped>
.mf2 {
  width: 100%;
}

.formMainScreen {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 300px;
  align-items: center;
  justify-content: center;
}

.formMainScreen p {
  width: 300px;
  text-align: center;
  color: #322929;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.form {
  flex-direction: column;
  max-width: 300px;
  align-items: center;
  justify-content: center;
}

.form button {
  width: 300px;
}

.formScreen {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  align-items: baseline;
  justify-content: center;
  margin-top: 30px;
}

.leftSide {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.leftSide h1 {
  width: 600px;
  color: #322929;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.leftSide h1 span {
  color: #ED7C0E;
}

.leftSide ul {
  padding: 20px;
  background: #ECF1F8;
}

.leftSide ul li {
  color: #322929;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.titleStages {
  margin-top: 70px;
  width: 567px;
  color: #322929;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.stagesBlock {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  padding: 0 100px;
  margin-bottom: 50px;
}

.stagesList {
  display: flex;
  width: 950px;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.stagesList_item {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  justify-content: baseline;
  border-radius: 2px;
  border: 1px solid #322929;
  height: 330px;
  padding: 50px 20px;
}

.stagesList_item .number {
  color: #ED7C0E;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}

.stagesList_item .title {
  width: 226px;
  color: #322929;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.stagesList_item .text {
  width: 225px;
  color: #322929;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.stagesList img {
  width: 350px;
  height: 350px;
  border-radius: 2px;
  border: 1px solid #ED7C0E;
  padding: 5px;
  position: absolute;
  bottom: -40px;
  right: -10px;
}

@media (max-width: 430px) {
  .stagesBlock {
    padding: 0 0;
    margin: 30px;
  }
  .stagesList {
    justify-content: center;
    flex-direction: column;
    max-width: 390px;
  }
  .stagesList img {
    display: none;
  }
  .titleStages {
    width: 350px;
  }
  .formScreen,
  .leftSide {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .leftSide h1 {
    width: 350px;
    font-size: 20px;
    text-align: center;
  }
}

@media (min-width: 431px) and (max-width: 1024px) {
  .stagesBlock {
    padding: 0 0;
    margin-top: 30px;
  }
  .stagesList {
    justify-content: center;
  }
  .stagesList_item {
    padding: 50px 10px;
  }
  .stagesList img {
    display: none;
  }
  .titleStages {
    width: 350px;
  }
  .formScreen,
  .leftSide {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .leftSide h1 {
    width: 350px;
    font-size: 20px;
    text-align: center;
  }
}
</style>
