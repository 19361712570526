<template>
  <section class="videoReviews">
    <h1>
      <span>{{ $t('videoReviews.titleSpan') }}</span>
      {{ $t('videoReviews.titleSuffix') }}
    </h1>
    <div class="videoList">
      <div class="videoItem">
        <video playsinline controls width="320" poster="@/assets/video/reviews/reviewposter1.webp" height="320" style="border-radius: 50%;">
          <source src="@/assets/video/reviews/review01.mp4" type="video/mp4">
        </video>
      </div>

      <div class="videoItem">
        <video playsinline controls width="320" poster="@/assets/video/reviews/reviewposter2.webp" height="320" style="border-radius: 50%;">
          <source src="@/assets/video/reviews/review02.mp4" type="video/mp4">
        </video>
      </div>

      <div class="videoItem">
        <video playsinline controls width="320" poster="@/assets/video/reviews/reviewposter3.webp" height="320" style="border-radius: 50%;">
          <source src="@/assets/video/reviews/review03.mp4" type="video/mp4">
        </video>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: 'VideoReviews',
}
</script>

<style scoped>
  .videoItem {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
  }

  .videoItem video {
    border-radius: 2px;
  }

  .videoItem h2 {
    font-size: 16px;
    font-weight: 700;
    color: #322929;
  }

  .videoItem p {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #322929;
  }

  .videoList {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    align-items: baseline;
    justify-content: center;
  }

  .videoReviews {
    margin: 80px 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    text-align: center;

  }

  .videoReviews h1 {
    width: 773px;
    text-align: center;
    font-size: 40px;
    font-weight: 900;
    color: #322929;

  }

  .videoReviews h1 span {
    color: #ED7C0E;
  }

  @media (max-width: 430px) {
    .videoReviews h1 {
      width: 350px;
    }
  }
</style>