<template>
  <div>
    <HeaderSite></HeaderSite>
  </div>
  <section class="aboutPage">
    <div class="topBlock">
      <h1 class="title" v-html="$t('aboutPage.topBlock.title')"></h1>
      <div class="OlegAppeal">
        <div class="leftSide">
          <img src="@/assets/oleg/3.webp" alt="">
          <p>{{ $t('aboutPage.topBlock.oleg') }}</p>
        </div>
        <div class="rightSide">
          <p class="rightSide_p1 animate__animated">{{ $t('aboutPage.topBlock.right.p1') }}</p>
          <p class="rightSide_p2 animate__animated" v-html="$t('aboutPage.topBlock.right.p2')"></p>
          <ul class="ul animate__animated">
            <li v-for="(itemKey, index) in topBlockRightList" :key="index">{{ $t(itemKey) }}</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="text">
      <!-- Section 1 -->
      <h1 v-html="$t('aboutPage.text.section1.h1')"></h1>
      <p v-if="$t('aboutPage.text.section1.lead')">{{ $t('aboutPage.text.section1.lead') }}</p>
      <ul>
        <li v-for="(itemKey, index) in section1List" :key="index">{{ $t(itemKey) }}</li>
      </ul>

      <!-- Section 2 -->
      <h1>{{ $t('aboutPage.text.section2.h1') }}</h1>
      <p>{{ $t('aboutPage.text.section2.p1') }}</p>
      <p>{{ $t('aboutPage.text.section2.p2') }}</p>
      
      <!-- Section 3 -->
      <p>{{ $t('aboutPage.text.section3.p') }}</p>
      <ul>
        <li v-for="(itemKey, index) in section3List" :key="index">{{ $t(itemKey) }}</li>
      </ul>
      
      <!-- Section 4 -->
      <h1>{{ $t('aboutPage.text.section4.h1') }}</h1>
      <p>{{ $t('aboutPage.text.section4.p') }}</p>
      <ul>
        <li v-for="(itemKey, index) in section4List" :key="index">{{ $t(itemKey) }}</li>
      </ul>
      
      <!-- Final paragraphs -->
      <p>{{ $t('aboutPage.text.final.p1') }}</p>
      <p>{{ $t('aboutPage.text.final.p2') }}</p>
      
      <main-form></main-form>
    </div>
  </section>
  <FooterSite></FooterSite>
</template>

<script>
export default {
  name: 'AboutPage',
  data() {
    return {
      topBlockRightList: [
        'aboutPage.topBlock.right.li1',
        'aboutPage.topBlock.right.li2',
        'aboutPage.topBlock.right.li3',
        'aboutPage.topBlock.right.li4'
      ],
      section1List: [
        'aboutPage.text.section1.li1',
        'aboutPage.text.section1.li2',
        'aboutPage.text.section1.li3',
        'aboutPage.text.section1.li4',
        'aboutPage.text.section1.li5',
        'aboutPage.text.section1.li6',
        'aboutPage.text.section1.li7',
        'aboutPage.text.section1.li8'
      ],
      section3List: [
        'aboutPage.text.section3.li1',
        'aboutPage.text.section3.li2',
        'aboutPage.text.section3.li3',
        'aboutPage.text.section3.li4',
        'aboutPage.text.section3.li5',
        'aboutPage.text.section3.li6',
        'aboutPage.text.section3.li7',
        'aboutPage.text.section3.li8',
        'aboutPage.text.section3.li9'
      ],
      section4List: [
        'aboutPage.text.section4.li1',
        'aboutPage.text.section4.li2',
        'aboutPage.text.section4.li3',
        'aboutPage.text.section4.li4',
        'aboutPage.text.section4.li5',
        'aboutPage.text.section4.li6'
      ]
    };
  }
};
</script>

<style scoped>
.aboutPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 115px;
}

.topBlock {
  background: #322929;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 50px 100px;
}

h1 {
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
}

p {
  font-size: 16px;
  font-weight: 500;
}

.title {
  width: 773px;
  text-align: center;
  font-size: 40px;
  font-weight: 900;
  text-transform: uppercase;
}

.title span {
  color: #ED7C0E;
}

.OlegAppeal {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 50px;
}

.leftSide {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.leftSide img {
  width: 240px;
  height: 300px;
  border-radius: 2px;
}

.rightSide {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.rightSide_p1 {
  width: 576px;
  font-size: 20px;
  font-weight: 250;
  text-transform: uppercase;
  color: #322929;
}

.rightSide_p2 {
  width: 637px;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.rightSide_p2 span {
  color: #ED7C0E;
}

.rightSide ul {
  padding: 20px;
}

.rightSide ul li {
  width: 411px;
  font-size: 16px;
  font-weight: 500;
}

@media (max-width: 430px) {
  .aboutPage {
    margin-top: 100px;
  }
  .title {
    width: 350px;
    font-size: 26px;
  }
  .OlegAppeal {
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
  }
  .rightSide {
    align-items: center;
  }
  .rightSide_p1,
  .rightSide_p2 {
    width: 350px;
    text-align: center;
  }
  .rightSide ul li {
    width: 320px;
  }
}

@media (min-width: 431px) and (max-width: 1024px) {
  .title {
    width: 750px;
    font-size: 26px;
  }
  .OlegAppeal {
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
  }
  .rightSide {
    align-items: center;
  }
  .rightSide_p1,
  .rightSide_p2 {
    width: 750px;
    text-align: center;
  }
  .rightSide ul li {
    width: 750px;
  }
}
</style>
