<template>
  <prototipePortfolio :slides="completedWorks"></prototipePortfolio>
</template>
  
<script>
import prototipePortfolio from '@/components/completedWorks/prototipeBlockPortfolio.vue'

export default {
  name: "CompletedBlock",
  components: {
    prototipePortfolio
  },
  data() {
    return {
      completedWorks: [
        {
          id: "item1",
          photos: [
            { id: 1, srcImg: "sliders/dubeshnya/01.webp" },
            { id: 2, srcImg: "sliders/dubeshnya/02.webp" },
            { id: 3, srcImg: "sliders/dubeshnya/03.webp" },
            { id: 4, srcImg: "sliders/dubeshnya/04.webp" },
            { id: 5, srcImg: "sliders/dubeshnya/05.webp" },
            { id: 6, srcImg: "sliders/dubeshnya/06.webp" },
            { id: 7, srcImg: "sliders/dubeshnya/07.webp" },
            { id: 8, srcImg: "sliders/dubeshnya/08.webp" },
            { id: 9, srcImg: "sliders/dubeshnya/09.webp" }
          ],
          name_portfolioItemKey: "completedWorks.item1.name_portfolioItem",
          location_pKey: "completedWorks.item1.location_p",
          footage_pKey: "completedWorks.item1.footage_p",
          cost_pKey: "completedWorks.item1.cost_p",
          period_pKey: "completedWorks.item1.period_p",
          completedWorks_pKey: "completedWorks.item1.completedWorks_p",
          portfolioLink: "/dubechnya"
        },
        {
          id: "item2",
          photos: [
            { id: 1, srcImg: "sliders/zapovitniy/01.webp" },
            { id: 2, srcImg: "sliders/zapovitniy/02.webp" },
            { id: 3, srcImg: "sliders/zapovitniy/03.webp" },
            { id: 4, srcImg: "sliders/zapovitniy/04.webp" }
          ],
          name_portfolioItemKey: "completedWorks.item2.name_portfolioItem",
          location_pKey: "completedWorks.item2.location_p",
          footage_pKey: "completedWorks.item2.footage_p",
          cost_pKey: "completedWorks.item2.cost_p",
          period_pKey: "completedWorks.item2.period_p",
          completedWorks_pKey: "completedWorks.item2.completedWorks_p",
          portfolioLink: "/zapovitniy"
        },
        {
          id: "item3",
          photos: [
            { id: 1, srcImg: "sliders/jkYaskraviy/01.webp" },
            { id: 2, srcImg: "sliders/jkYaskraviy/02.webp" },
            { id: 3, srcImg: "sliders/jkYaskraviy/03.webp" },
            { id: 4, srcImg: "sliders/jkYaskraviy/04.webp" },
            { id: 5, srcImg: "sliders/jkYaskraviy/05.webp" },
            { id: 6, srcImg: "sliders/jkYaskraviy/06.webp" },
            { id: 7, srcImg: "sliders/jkYaskraviy/07.webp" },
            { id: 8, srcImg: "sliders/jkYaskraviy/08.webp" },
            { id: 9, srcImg: "sliders/jkYaskraviy/09.webp" }
          ],
          name_portfolioItemKey: "completedWorks.item3.name_portfolioItem",
          location_pKey: "completedWorks.item3.location_p",
          footage_pKey: "completedWorks.item3.footage_p",
          cost_pKey: "completedWorks.item3.cost_p",
          period_pKey: "completedWorks.item3.period_p",
          completedWorks_pKey: "completedWorks.item3.completedWorks_p",
          portfolioLink: "/yaskraviy"
        },
        {
          id: "item4",
          photos: [
            { id: 1, srcImg: "sliders/sinergiyaCity/01.webp" },
            { id: 2, srcImg: "sliders/sinergiyaCity/02.webp" },
            { id: 3, srcImg: "sliders/sinergiyaCity/03.webp" },
            { id: 4, srcImg: "sliders/sinergiyaCity/04.webp" },
            { id: 5, srcImg: "sliders/sinergiyaCity/05.webp" },
            { id: 6, srcImg: "sliders/sinergiyaCity/06.webp" },
            { id: 7, srcImg: "sliders/sinergiyaCity/07.webp" },
            { id: 8, srcImg: "sliders/sinergiyaCity/08.webp" },
            { id: 9, srcImg: "sliders/sinergiyaCity/09.webp" },
            { id: 10, srcImg: "sliders/sinergiyaCity/10.webp" },
            { id: 11, srcImg: "sliders/sinergiyaCity/11.webp" }
          ],
          name_portfolioItemKey: "completedWorks.item4.name_portfolioItem",
          location_pKey: "completedWorks.item4.location_p",
          footage_pKey: "completedWorks.item4.footage_p",
          cost_pKey: "completedWorks.item4.cost_p",
          period_pKey: "completedWorks.item4.period_p",
          completedWorks_pKey: "completedWorks.item4.completedWorks_p",
          portfolioLink: "/sinergiyacity"
        },
        {
          id: "item5",
          photos: [
            { id: 1, srcImg: "sliders/varshavskyi/01.webp" },
            { id: 2, srcImg: "sliders/varshavskyi/02.webp" },
            { id: 3, srcImg: "sliders/varshavskyi/03.webp" },
            { id: 4, srcImg: "sliders/varshavskyi/04.webp" },
            { id: 5, srcImg: "sliders/varshavskyi/05.webp" },
            { id: 6, srcImg: "sliders/varshavskyi/06.webp" }
          ],
          name_portfolioItemKey: "completedWorks.item5.name_portfolioItem",
          location_pKey: "completedWorks.item5.location_p",
          footage_pKey: "completedWorks.item5.footage_p",
          cost_pKey: "completedWorks.item5.cost_p",
          period_pKey: "completedWorks.item5.period_p",
          completedWorks_pKey: "completedWorks.item5.completedWorks_p",
          portfolioLink: "/varshavskiy"
        },
        {
          id: "item6",
          photos: [
            { id: 1, srcImg: "sliders/fainaTown/01.webp" },
            { id: 2, srcImg: "sliders/fainaTown/02.webp" },
            { id: 3, srcImg: "sliders/fainaTown/03.webp" },
            { id: 4, srcImg: "sliders/fainaTown/04.webp" },
            { id: 5, srcImg: "sliders/fainaTown/05.webp" },
            { id: 6, srcImg: "sliders/fainaTown/06.webp" },
            { id: 7, srcImg: "sliders/fainaTown/07.webp" },
            { id: 8, srcImg: "sliders/fainaTown/08.webp" }
          ],
          name_portfolioItemKey: "completedWorks.item6.name_portfolioItem",
          location_pKey: "completedWorks.item6.location_p",
          footage_pKey: "completedWorks.item6.footage_p",
          cost_pKey: "completedWorks.item6.cost_p",
          period_pKey: "completedWorks.item6.period_p",
          completedWorks_pKey: "completedWorks.item6.completedWorks_p",
          portfolioLink: "/faynatown"
        }
      ],
      uniqueKey: 0
    };
  },
  methods: {
    subscribeToComponentAgain() {
      this.uniqueKey += 1;
    }
  }
};
</script>

<style>

</style>
