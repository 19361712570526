import { createApp } from 'vue'
import App from './App.vue'
import components from '@/components/UI'
import VueSplide from '@splidejs/vue-splide';
import PrimeVue from 'primevue/config';
import VueLazyLoad from 'vue3-lazyload';
import VueGlide from 'vue-glide-js';


import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import router from '@/router'
import VueTheMask from 'vue-the-mask'

import Button from "primevue/button"

import { createI18n } from 'vue-i18n/dist/vue-i18n.esm-bundler.js'
import ua from '@/i18n/ua.json'
import ru from '@/i18n/ru.json'


const messages = {
  ua,
  ru
}

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'ua', // язык по умолчанию
  fallbackLocale: 'ru',
  messages,
  returnObjects: true,
})

export default i18n



// Обновление локали при каждом переходе маршрута
router.beforeEach((to, from, next) => {
  const lang = to.params.lang
  if (lang && ['ua', 'ru'].includes(lang)) {
    i18n.global.locale = lang  // <-- так работает только при legacy: false
  }
  next()
})

console.log('Текущая локаль:', i18n.global.locale) // должно быть "ua"
console.log('Сообщения для ua:', i18n.global.getLocaleMessage('ua'))
console.log('Перевод mainTitle:', i18n.global.t('mainTitle')) 



library.add(faChevronDown);


const app = createApp(App)
app.use(VueGlide);
app.use(VueLazyLoad);
app.use( VueSplide );
app.use( PrimeVue );
app.use(router);
app.use(VueTheMask);
app.use(i18n)


components.forEach(component => {
  app.component(component.name, component)
});
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('PrimeButton', Button);



app.mount('#app')
