<template>
  <section>
    <HeaderSite></HeaderSite>
    <div class="shop">
      <p>{{ $t('fasting.t1') }}</p>
      <p>{{ $t('fasting.t2') }}</p>
      <MainForm2v2></MainForm2v2>
      <!-- <button><a href="#">Перейти на інтернет-магазин</a></button> -->
    </div>
  </section>
  <footer-site></footer-site>
</template>

<script>

export default {

}
</script>

<style scoped>
  .header .headerMenu menu :nth-child(7) {
    color: #ED7C0E;
  }

  .shop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 230px 0;
  }

  .shop img {
    width: 300px;
    height: 300px;
    border-radius: 50%;
  }

  .shop h2 {
    font-size: 24px;
    font-weight: 900;
  }

  .shop p {
    font-size: 16px;
    font-weight: 500;
    max-width: 350px;
    text-align: center;
  }

  .shop button {
    width: 300px;
    height: 60px;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    border-radius: 2px;
    background: #ED7C0E;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
    color: white;
  }

  @media (max-width: 430px) {
    .header {
      background: #322929;
      height: 100px;
      width: 100%;
    }

    .shop {
      margin: 120px 0;
    }
  }



</style>