<template>
  <section class="advantages">
    <div class="advantagesList">
      <div class="advantagesList_item animate__animated">
        <img src="@/assets/icons/advantages/01.svg" alt="">
        <h2>{{ $t('advantages.items.item1.title') }}</h2>
        <p>{{ $t('advantages.items.item1.description') }}</p>
      </div>

      <div class="advantagesList_item animate__animated">
        <img src="@/assets/icons/advantages/02.svg" alt="">
        <h2>{{ $t('advantages.items.item2.title') }}</h2>
        <p>{{ $t('advantages.items.item2.description') }}</p>
      </div>

      <div class="advantagesList_item animate__animated">
        <img src="@/assets/icons/advantages/03.svg" alt="">
        <h2>{{ $t('advantages.items.item3.title') }}</h2>
        <p>{{ $t('advantages.items.item3.description') }}</p>
      </div>

      <div class="advantagesList_item animate__animated">
        <img src="@/assets/icons/advantages/04.svg" alt="">
        <h2>{{ $t('advantages.items.item4.title') }}</h2>
        <p>{{ $t('advantages.items.item4.description') }}</p>
      </div>
    </div>
    
    <div class="formMainScreen frm animate__animated">
      <p>{{ $t('advantages.form.text') }}</p>
      <MainForm2></MainForm2>
    </div>
  </section>
</template>

<script>
import $ from 'jquery';
import 'animate.css';


export default {
  name: 'AdvantagesBlock',
  mounted() {
  $(document).ready(function() {
    $(window).scroll(function() {

      // Получаем элемент с классом .advantages
      let advantagesElement = $('.advantages');

      // Проверяем, существует ли элемент
      if (advantagesElement.length > 0) {
        // Получаем отступ (offset) элемента от верхней части страницы
        let elementOffset = advantagesElement.offset().top;

        // Получаем текущую позицию прокрутки
        let scrollPosition = $(this).scrollTop();

        let animatedEl = ['.advantagesList_item'];

        // Проверяем, долистали ли до элемента
        if (scrollPosition >= elementOffset - $(window).height() / 2) {
          animatedEl.forEach(el => {
            $(el).addClass('animate__fadeInLeft');
            $(el).removeClass('animate__fadeOutLeft');
          });
          $('.frm').addClass('animate__fadeInLeft');
          $('.frm').removeClass('animate__fadeOutLeft');
        }
        // Если вы хотите удалить класс, если пользователь прокрутил обратно наверх
        else {
          animatedEl.forEach(el => {
            $(el).removeClass('animate__fadeInLeft');
            $(el).addClass('animate__fadeOutLeft');
          });
          $('.frm').removeClass('animate__fadeInLeft');
          $('.frm').addClass('animate__fadeOutLeft');
        }
      }
    });
  });
}

}
</script>

<style scoped>

  .advantages {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 30px;
    background: #322929;
    padding: 60px 100px;
  }

  .advantagesList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items:baseline;
    justify-content: center;
  }

  .advantagesList_item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }

  .advantagesList_item img {
    width: 50px;
    height: 50px;
  }

  .advantagesList_item h2 {
    width: 190px;
    color: #ECF1F8;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }

  .advantagesList_item p {
    width: 185px;
    color: #ECF1F8;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
  }

  .formMainScreen {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
  }

  .formMainScreen p {
    width: 300px;
    text-align: center;
    color: #ECF1F8;
    font-size: 16px;
    font-weight: 500;
  }

  .form {
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    color: white;
    
  }

  .form button {
    width: 300px;
  }


  @media (max-width: 430px) {
    .advantages {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 20px;
  }
}

@media (min-width: 431px) and (max-width: 1024px) {
    .advantages {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
  }
}

</style>