<template>
  <section class="aboutUs">
    <h1 class="title">
      <span>{{ $t('aboutUs.mainTitleSpan') }}</span> {{ $t('aboutUs.mainTitleSuffix') }}
    </h1>
    <div class="OlegAppeal">
      <div class="leftSide">
        <img src="@/assets/oleg/3.webp" class="imgOleg" alt="">
        <p>{{ $t('aboutUs.ownerInfo') }}</p>
      </div>
      <div class="rightSide">
        <p class="rightSide_p1">{{ $t('aboutUs.appeal.quote') }}</p>
        <p class="rightSide_p2">
          <span>{{ $t('aboutUs.appeal.subTitleSpan') }}</span>{{ $t('aboutUs.appeal.subTitleSuffix') }}
        </p>
        <ul class="ul">
          <li>{{ $t('aboutUs.list.item1') }}</li>
          <li>{{ $t('aboutUs.list.item2') }}</li>
          <li>{{ $t('aboutUs.list.item3') }}</li>
          <li>{{ $t('aboutUs.list.item4') }}</li>
        </ul>
      </div>
    </div>

    <img 
      class="videoCallimg" 
      src="@/assets/icons/play-button.png" 
      :alt="$t('aboutUs.videoAlt')" 
      @click="openFullScreen">

    <div v-if="isFullScreen" class="fullScreenOverlay" @click="closeFullScreen">
      <div class="fullScreenVideo">
        <iframe width="360" height="515" src="https://www.youtube.com/embed/y0jwnB2QJKE" frameborder="0" allowfullscreen></iframe>
        <button class="closeButton" @click="closeFullScreen">{{ $t('aboutUs.videoClose') }}</button>
      </div>
    </div>

    <h2 class="title2" v-html="$t('aboutUs.subtitle')"></h2>
    <section class="slider">
      <div id="main">
        <div class="attach">
          <div class="slider_case">
            <v-slider v-bind="options">
              <div class="member" v-for="slide in team" :key="slide.id">
                <img :src="getImgUrl(slide.srcImg)" alt="">
                <p class="memberName">{{ $t(slide.nameKey) }}</p>
                <p class="memberPosition">{{ $t(slide.positionKey) }}</p>
              </div>
            </v-slider>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import slider from '@/components/SliderComponents/sliderV.vue'
import { defineComponent } from 'vue';

export default defineComponent({
  name: "AboutUs",
  components: {
    'v-slider': slider
  },
  data() {
    return {
      isFullScreen: false,
      team: [
        { id: '12', srcImg: 'oleg.webp', nameKey: 'aboutUs.team.oleg.name', positionKey: 'aboutUs.team.oleg.position' },
        { id: '42', srcImg: 'malyar3.webp', nameKey: 'aboutUs.team.vitaliy.name', positionKey: 'aboutUs.team.vitaliy.position' },
        { id: '43', srcImg: 'malyar2.webp', nameKey: 'aboutUs.team.oleksandr1.name', positionKey: 'aboutUs.team.oleksandr1.position' },
        { id: '22', srcImg: 'igorPlitochnik.webp', nameKey: 'aboutUs.team.igor.name', positionKey: 'aboutUs.team.igor.position' },
        { id: '32', srcImg: 'sashaElectric.webp', nameKey: 'aboutUs.team.oleksandr2.name', positionKey: 'aboutUs.team.oleksandr2.position' },
        { id: '44', srcImg: 'sergeiMalyar.webp', nameKey: 'aboutUs.team.sergiy.name', positionKey: 'aboutUs.team.sergiy.position' },
        { id: '45', srcImg: 'yanaPostachannya.webp', nameKey: 'aboutUs.team.yana.name', positionKey: 'aboutUs.team.yana.position' },
        { id: '46', srcImg: 'olenadesign.webp', nameKey: 'aboutUs.team.olena.name', positionKey: 'aboutUs.team.olena.position' }
      ],
      options: {
        items: 3,
        margin: 10,
        nav: true,
        dots: true,
        loop: true,
        timing: 'cubic-bezier(0, 0.72, 0.64, 1.06)',
        offset: 1,
        prevNav: '<',
        nextNav: '>',
        sibling: true,
        responsive: {
          0: { items: 1 },
          700: { items: 2 },
          1024: { items: 3 }
        }
      }
    };
  },
  methods: {
    getImgUrl(pic) {
      return require('@/assets/team/' + pic);
    },
    openFullScreen() {
      this.isFullScreen = true;
      document.body.style.overflow = 'hidden';
    },
    closeFullScreen() {
      this.isFullScreen = false;
      document.body.style.overflow = '';
    }
  }
});
</script>


<style scoped>

.videoCallimg {
  width: 200px;
  margin: 50px 0;
  animation: pulse 0.5s infinite alternate; 
}

@keyframes pulse {
  0% {
    transform: scale(1); /* Початковий масштаб */
  }
  100% {
    transform: scale(1.1); /* Кінцевий масштаб */
  }
}

.title, .title2 {
    width: 773px;
    text-align: center;
    font-size: 40px;
    font-weight: 900;
  }
  .title span {
    color: #ED7C0E;
  }

  .OlegAppeal {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }


  .leftSide {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }

 .leftSide img {
    width: 220px;
    height: 300px;
    border-radius: 2px;
 }

 .leftSide p {
  max-width: 220px;
 }

 .rightSide {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
 }

 .rightSide_p1 {
  width: 576px;
  font-size: 20px;
  font-weight: 250;
  color: #322929;
 }

 .rightSide_p2 {
  width: 637px;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
 }

 .rightSide_p2 span {
  color:#ED7C0E;
 }

 .rightSide ul{
  padding: 20px;
  background: #ECF1F8;
 }

 .rightSide ul li{
  width: 411px;
  font-size: 16px;
  font-weight: 500;
  color: #322929;
 }
 
 .team {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
 }

 .team .member {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
 }

 .member img {
  width: 230px;
  height: 350px;
  border-radius: 2px;
 }

 .aboutUs {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
 }

 .leftSide p {
  font-size: 15px;
  font-weight: 300;
  color:  #322929;
  text-align: center;
 }

 .memberName {
  color:  #322929;
  font-size: 16px;
  font-weight: 700;
 }

 .memberPosition {
  color: #322929;
  font-size: 16px;
  font-weight: 500;
 }


.slider {
  width: 750px;
}

.carousel__item {
  height: 400px;
  width: 250px;
  border-radius: 2px;
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.carousel__item__bottom {
  height: 200px;
  width: 140px;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

#galery {
  height: 100%;
}



 @media (max-width: 430px) {

 .member img {
  width: 260px;
  height: 380px;
 }

  .title, .title2 {
    width: 350px;
    font-size: 26px; 
  }
  .OlegAppeal {
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


 .rightSide {
  align-items: center;
 }

 .rightSide_p1 {
  width: 350px;
  text-align: center;
 }

 .rightSide_p2 {
  width: 350px;
  text-align: center;
 }


 .rightSide ul li{
  width: 320px;
 }

 
 

 .aboutUs {
  margin-top: 30px;
 }


 .slider {
  width: 350px;
}

.carousel__item {
  height: 300px;
  width: 220px;
  border-radius: 2px;
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__item__bottom {
  height: 150px;
}

.carousel__slide {
  padding: 10px;
}
}



@media (min-width: 431px) and (max-width: 1024px) {
  .title, .title2 {
    width: 750px;
    font-size: 26px; 
  }
  .OlegAppeal {
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


 .rightSide {
  align-items: center;
 }

 .rightSide_p1 {
  width: 750px;
  text-align: center;
 }

 .rightSide_p2 {
  width: 750px;
  text-align: center;
 }


 .rightSide ul li{
  width: 750px;
 }

 
 

 .aboutUs {
  margin-top: 30px;
 }

}


.item {
  height: 350px;
}

.fullScreenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullScreenImage img {
  max-width: 80%;
  max-height: 90vh;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  z-index: 1000;
}
</style>