<!-- src/components/Layout.vue -->
<template>
    <router-view />
  </template>
  
  <script>
  export default {
    name: 'LayoutComponent'
  }
  </script>
  