import { createRouter, createWebHistory } from "vue-router";
import LayoutComponent from "@/components/LayoutComponent.vue";
import MainPage from "@/pages/MainPage.vue";
import AboutPage from '@/pages/AboutPage.vue';
import PortfolioPage from '@/pages/PortfolioPage.vue';
import ReviewsPage from '@/pages/ReviewsPage.vue';
import CalculatorPage from '@/pages/CalculatorPage.vue';
import NowPage from '@/pages/NowPage.vue';
import FastingPage from '@/pages/FastingPage.vue';
import ContactsPage from '@/pages/ContactPage.vue';
import DubechnyaComponent from '@/components/completedWorks/PotrfolioComponents/dubechnyaComponent.vue';
import ZapovitniyComponent from '@/components/completedWorks/PotrfolioComponents/zapovitniyComponent.vue';
import faynaTownComponent from '@/components/completedWorks/PotrfolioComponents/faynaTownComponent.vue';
import sinergiyaCityComponent from '@/components/completedWorks/PotrfolioComponents/synergiyaCityComponent.vue';
import varshavskiyComponent from '@/components/completedWorks/PotrfolioComponents/varshavskiyComponent.vue';
import yaskraviyComponent from '@/components/completedWorks/PotrfolioComponents/yaskraviyComponent.vue';

const routes = [
  {
    path: '/:lang(ua|ru)',
    component: LayoutComponent,
    children: [
      { path: '', component: MainPage },
      { path: 'about', component: AboutPage },
      { path: 'portfolio', component: PortfolioPage },
      { path: 'reviews', component: ReviewsPage },
      { path: 'calculator', component: CalculatorPage },
      { path: 'now', component: NowPage },
      { path: 'fasting', component: FastingPage },
      { path: 'contacts', component: ContactsPage },
      { path: 'dubechnya', component: DubechnyaComponent },
      { path: 'zapovitniy', component: ZapovitniyComponent },
      { path: 'faynatown', component: faynaTownComponent },
      { path: 'sinergiyacity', component: sinergiyaCityComponent },
      { path: 'varshavskiy', component: varshavskiyComponent },
      { path: 'yaskraviy', component: yaskraviyComponent }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/ua'
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
});

export default router;
