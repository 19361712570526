<template>
  <section>
    <header class="header">
      <a href="https://www.roskin.com.ua/">
        <img class="headerLogo" src="@/assets/logo.png" alt="">
      </a>
      <div class="divided"></div>
      <div class="headerMenu">
        <main-menu class="menu"></main-menu>
      </div>
      <div class="divided"></div>
      <div class="headerTop">
        <div class="emaiBox">
          <p>roskin.o.r@gmail.com</p>
        </div>
        <div class="messangerContact">
          <a href="https://wa.me/380675481288" target="_blank">
            <img class="m" src="@/assets/icons/contactIcons/waContact.png" alt="WhatsApp">
          </a>
          <a href="https://t.me/+380675481288" target="_blank">
            <img class="m" src="@/assets/icons/contactIcons/tgContact.png" alt="Telegram">
          </a>
          <a href="viber://chat?number=%2B380675481288" target="_blank">
            <img class="m" src="@/assets/icons/contactIcons/vContact.png" alt="Viber">
          </a>
        </div>
        <div class="phoneBox">
          <a href="tel:+380665246345">067 548 12 88</a>
          <a href="tel:+380665246345">073 548 12 88</a>
          <a href="tel:+380665246345">066 524 63 45</a>
        </div>
      </div>
      <!-- Переключатель языка -->
      <div class="langSwitch">
        <p class="langTitle">{{ $t('languageTitle') }}</p>
        <select v-model="currentLocale" @change="changeLanguage">
          <option value="ua">Українська</option>
          <option value="ru">Русский</option>
        </select>
      </div>
    </header>
  </section>
</template>

<script>
export default {
  name: 'HeaderSite',
  data() {
    return {
      // Убедитесь, что начальное значение совпадает с текущей локалью (например, 'ua' или 'ru')
      currentLocale: this.$i18n.locale
    }
  },
  methods: {
  changeLanguage(e) {
    const newLocale = e.target.value;
    this.$i18n.locale = newLocale;
    // Разбиваем текущий путь на сегменты
    const segments = this.$route.path.split('/');
    // Если первый сегмент пустой (так как путь начинается со слэша), то второй элемент – это язык
    if (segments.length > 1) {
      segments[1] = newLocale;
    } else {
      segments.push(newLocale);
    }
    const newPath = segments.join('/');
    this.$router.push(newPath);
  }
}

}
</script>

<style scoped>

.langSwitch {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.langSwitch select {
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 5px;
  color: #ffffff;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  /* Добавим плавное изменение при наведении */
  transition: border-color 0.3s, background 0.3s;
}

.langSwitch select:hover {
  background: rgba(255, 255, 255, 0.1);
}

.langSwitch select:focus {
  border-color: #ED7C0E;
}


.m {
  width: 25px;
  height: 25px;
}

.messangerContact {
  display: flex;
  gap: 5px;
}

.headerLogo {
  max-width: 180px;
}

.divided {
  width: 0.5px;
  height: 70px;
  background: #ffffff;
}

a {
  text-decoration: none; 
  color: #ffffff;
}

.headerTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  gap: 10px;
  flex-shrink: 0;
  flex-wrap: wrap;
}

.emaiBox {
  display: flex;
  align-items: center;
  gap: 10px;
}

.phoneBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.emaiBox p, .phoneBox a, .langTitle {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}

.emaiBox img, .phoneBox img {
  width: 32px;
  height: 32px;
}

.socialLinks {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.socialLinks a img {
  width: 32px;
}

.header {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 15px;
  background:#322929;
  color: white;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
}

.menu {
  color: white;
}

.headerLogo {
  width: 220px;
  height: 80px;
}

.sn {
  color: #ffffff;
}

.tels {
  display: grid;
  grid-template-columns: 2;
  gap: 5px;
}

@media (max-width: 430px) {
  .header {
    display: none;
  }
}
</style>
