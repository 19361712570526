<template>
  <section>
    <HeaderSite></HeaderSite>
    <div class="contact">
      <h2>{{ $t('contacts.contactUsText') }}</h2>
        <div class="container mt-5">
          <div class="info">
            <p>{{ $t('contacts.contactUsCity') }}</p>
            <a href="tel:+380665246345">066 524 63 45</a>
            <p>roskin.o.r@gmail.com</p>
            <p>ФОП Роскін</p>
          </div>

          <div class="socialLinks">
            <a href="https://t.me/roskinbud" target="_blank" rel="noopener">
              <img class="sn" src="@/assets/icons/telegram-icon.svg" alt="">
            </a>
            <a href="https://www.facebook.com/olegroskinbud" target="_blank" rel="noopener">
              <img class="sn" src="@/assets/icons/facebook-icon.svg" alt="">
            </a>
            <a href="https://www.instagram.com/olegroskin/" target="_blank" rel="noopener">
              <img class="sn" src="@/assets/icons/instagram-icon.svg" alt="">
            </a>
          </div>   
        <!-- <form class="form" id="contactForm" action="/sendForm.php" method="post">

            <label class="form-label">Як до вас звертатись:
              <input type="text" class="form-control" id="contactName" required>
            </label>
            <label class="form-label">Номер телефону:
              <input type="tel" class="form-control" id="contactPhone" required placeholder="+380 00 000 00 00">
            </label> 

            <label class="form-label">Повідомлення:
              <textarea class="form-control" id="contactMessage" rows="5" required></textarea>
            </label>

          <button type="submit" class="btn btn-primary send-form" id="submit2">Відправити</button>
        </form> -->
      </div>
    </div>
  </section>
  <footer-site></footer-site>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  methods: {
    
  }
}
</script>

<style scoped>

.socialLinks {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .socialLinks a img {
    width: 32px;
    height: 32px;
  }


a {
  text-decoration: none;
  color: #322929;
}
.contact {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
}
  .header .headerMenu menu :nth-child(9) {
    color: #ED7C0E;
  }

  h2 {
    text-align: center;
    margin-top: 50px;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding: 50px;
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }

  input {
    width: 300px;
    height: 40px;
    padding-left: 15px;
  }

  label {
    display: flex;
    flex-direction: column;
    gap: 5px;

  }

  .btn {
    width: 100%;
    height: 60px;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    border-radius: 2px;
    background: #ED7C0E;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px;
    gap: 10px;
  }



  @media (max-width: 430px) {
    .header {
      background: #322929;
      height: 100px;
      width: 100%;
    }

    .contact {
      margin-top: 120px;
    }
  }



</style>