<template>
  <div>
    <Menu
      v-bind="this.$attrs"
      :isOpen="isOpen"
      @openMenu="openMenu"
      @closeMenu="closeMenu"
    >
      <slot></slot>
    </Menu>
  </div>
</template>

<script>
import Menu from '@/components/menu/Menu.vue';

export default {
  name: 'SlideMenu',
  components: {
    Menu
  },
  props: {
    isOpen: Boolean
  },
  methods: {
    openMenu() {
      this.$emit('openMenu');
    },
    closeMenu() {
      this.$emit('closeMenu');
    }
  }
};
</script>
