<template>
    <form action="sendForm.php" method="post">
      <div class="selectWrapper">
        <select name="option" class="selectMessangers" id="selectM">
          <option value="WhatsApp" selected>WhatsApp</option>
          <option value="Telegram">Telegram</option>
          <option value="Viber">Vider</option>
        </select>
      </div>
      <div class="numberBox">
        <!-- <div class="countryPref">
          +38
        </div> -->
        <!-- <input type="tel" class="phone" placeholder="Введіть номер телефону" name="tel" v-mask="'### ### ## ##'" pattern="[0-9]{3} [0-9]{3} [0-9]{2} [0-9]{2}" required title="Ви ввели номер некоректно"> -->
        <input type="tel" class="phone" :placeholder="$t('mainBtn.phonePlaceholder')" name="tel" required>
      </div>
      <main-button id="submit2" class="send-form">{{ $t('mainBtn.btnName') }}</main-button>
    </form>
    <div class="messangerContact" style="display: flex; gap: 5px;">
          <a href="https://wa.me/380675481288" target="_blank">
            <img class="m" src="@/assets/icons/contactIcons/waContact.png" alt="WhatsApp" width="45px">
          </a>

          <a href="https://t.me/+380675481288" target="_blank">
            <img class="m" src="@/assets/icons/contactIcons/tgContact.png" alt="Telegram" width="45px">
          </a>

          <a href="viber://chat?number=%2B380675481288" target="_blank">
            <img class="m" src="@/assets/icons/contactIcons/vContact.png" alt="Viber" width="45px">
          </a>
        </div>
    
</template>

<script>


export default {
  name: 'MainForm2',
  mounted() {
    if (window.location.href.includes('/sendForm')) {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-11367489649/CyxTCIKPg7cZEPHAuKwq'
      });
    }
  }
  
}
</script>

<style scoped>
 .phone {
    width: 300px !important;
    height: 60px;
    border: none;
    border-radius: 2px;
    padding-left: 10px;
    /* padding: 0 15px; */


  }

  .selectMessangers {
    width: 300px !important;
    height: 60px;
    overflow: hidden;
  }

  .selectWrapper {
      position: relative;
      display: inline-block;
      width: 301px;
      border: 0.5px solid #322929;
      box-sizing: border-box !important;
    }

    .selectWrapper select {
      width: 100%;
      padding: 10px 40px 10px 20px; /* <-- справа добавили место под стрелку */
      font-size: 16px;
      border: none;
      background: white;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      cursor: pointer;
      box-sizing: border-box;
    }


    .selectWrapper::after {
      content: '▼'; /* стрелочка вниз */
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      pointer-events: none;
      font-size: 14px;
      color: #333;
    }

  .phone {
    border: 0.5px solid #322929;
  }



  #submit2 {
    width: 300px;
  }

  form {
    display: flex;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;

  }


  select, select option {
    color: #322929;
  }

  .numberBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 63px;
    width: 300px;
  }

  .countryPref {
    height: 100%;
    border: 0.5px solid #322929;
    border-bottom-left-radius:  2px;
    border-top-left-radius:  2px;
    border-right: none;
    background: #322929;
    color: white;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 15px;
    margin-right: -1px;
  }


  @media (max-width: 430px) {
    form {
    justify-content: center;
  }

  .selectMessangers, .phone, #submit2,   .numberBox {
    width: 100%;
  }


}

</style>