<template>
  <section class="priceBlock">
    <h1 class="main_title">{{ $t('priceBlock.main_title') }}</h1>
    <div class="priceList">
      <div v-for="(category, index) in categories" :key="index" class="priceList_item">
        <p class="titleItem">{{ $t(category.titleKey) }}</p>
        <p class="subtitleItem">{{ $t(category.subtitleKey) }}</p>
        <p class="priceItem">{{ $t(category.priceKey) }}</p>
        <div class="options">
          <div v-for="(itemKey, itemIndex) in category.itemKeys" :key="itemIndex" class="checkedItem">
            <img src="@/assets/icons/cheked.svg" alt="">
            <p class="checkedItem_text">{{ $t(itemKey) }}</p>
          </div>
        </div>
        <MainForm2v2></MainForm2v2>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PriceBlock',
  data() {
    return {
      categories: [
        {
          id: "category1",
          titleKey: "priceBlock.categories.category1.title",
          subtitleKey: "priceBlock.categories.category1.subtitle",
          priceKey: "priceBlock.categories.category1.price",
          itemKeys: [
            "priceBlock.categories.category1.items.item1",
            "priceBlock.categories.category1.items.item2",
            "priceBlock.categories.category1.items.item3",
            "priceBlock.categories.category1.items.item4",
            "priceBlock.categories.category1.items.item5",
            "priceBlock.categories.category1.items.item6",
            "priceBlock.categories.category1.items.item7"
          ]
        },
        {
          id: "category2",
          titleKey: "priceBlock.categories.category2.title",
          subtitleKey: "priceBlock.categories.category2.subtitle",
          priceKey: "priceBlock.categories.category2.price",
          itemKeys: [
            "priceBlock.categories.category2.items.item1",
            "priceBlock.categories.category2.items.item2",
            "priceBlock.categories.category2.items.item3",
            "priceBlock.categories.category2.items.item4",
            "priceBlock.categories.category2.items.item5"
          ]
        },
        {
          id: "category3",
          titleKey: "priceBlock.categories.category3.title",
          subtitleKey: "priceBlock.categories.category3.subtitle",
          priceKey: "priceBlock.categories.category3.price",
          itemKeys: [
            "priceBlock.categories.category3.items.item1",
            "priceBlock.categories.category3.items.item2",
            "priceBlock.categories.category3.items.item3",
            "priceBlock.categories.category3.items.item4",
            "priceBlock.categories.category3.items.item5",
            "priceBlock.categories.category3.items.item6",
            "priceBlock.categories.category3.items.item7"
          ]
        }
      ]
    };
  }
};
</script>

<style scoped>


.priceBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  margin: 100px 100px;
}

.main_title {
  text-align: center;
  color: #322929;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  max-width: 650px;
  margin-bottom: 50px;
}

.priceList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  align-items: top;
  justify-content: center;
  text-align: center;
}

.priceList_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
  border-radius: 2px;
  border: 1px solid #ECF1F8;
  padding: 30px 10px;
  max-width: 350px;
}



.titleItem {
  color: #322929;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.subtitleItem {
  color: #322929;
  min-height: 50px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.priceItem {
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  padding: 0px 50px;
  border-radius: 2px;
  background: #322929;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.40);
}

.checkedItem {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.checkedItem img {
  width: 24px;
  height: 24px;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  padding: 15px;
  height: 290px;
}

@media (max-width: 430px) {
  .main_title {
    font-size: 26px;
    width: 350px;
  }

  .priceBlock {
    margin: 80px 20px;
  }

  .options {
    height: auto;
  }
  .priceList {
    display: flex;
    flex-direction: column;
  }
}
</style>
