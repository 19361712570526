<template>
  <HeaderSite></HeaderSite>
  <section class="reviewsBclock">
    <PhotoReviews></PhotoReviews>
    <VideoReviews></VideoReviews>
  </section>
  <footer-site></footer-site>
</template>

<script>
import PhotoReviews from '@/components/MainPage/11PhotoReviews.vue'
import VideoReviews from '@/components/MainPage/08VideoReviews.vue'
export default {
  components: {
    PhotoReviews,
    VideoReviews
  }
}
</script>

<style scoped>
  .header .headerMenu menu :nth-child(4) {
    color: #ED7C0E;
  }

  .reviewsBclock {
    padding: 155px 0;
  }

  @media (max-width: 430px) {
    .header {
      background: #322929;
      height: 100px;
      width: 100%;
    }

    .reviewsBclock {
        padding: 100px 0;
      }
  }


</style>